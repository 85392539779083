:root{
  --background-color: #edf3f1;
  --card-color: #ffffff;
  --primary-text-color: #353d38;
  --secondary-text-color: #ffffff;
  --primary-color: #71bf97;
  --warning-color: #f39c12;
  --danger-color: #e74c3c;
  --neutral-color: #7f8c8d; 
}

body, html{
  margin: 0;
  padding: 0;
  height: 100%;

  font-family: 'Roboto', sans-serif;
  background-color: var(--background-color);
}

.appContainer{
  min-height: 100vh;
  background-image: url('./img/backgroundImage.jpg');
  background-color: var(--background-color);
  background-size: cover;
}

.navbar{
  padding-top: 4rem;
  padding-bottom: 5rem;

  text-align: center;
}

.resultsContainer{
  background-color: var(--background-color);
  bottom: 0;
  height: 100vh - 1rem;
  padding: 1rem;
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
  box-shadow: rgba(12, 12, 13, 0.5) 0px 8px 24px;
  padding-bottom: 120px;
}

.resultsContainerGrabber{
  height: 0.3rem;
  width: 2.5rem;
  margin-left: auto;
  margin-right: auto;

  border-radius: 5rem;
  background-color: var(--primary-text-color);
  opacity: 0.5;
}

.promptCounter{
  font-family: 'Poppins', sans-serif;
  color: var(--secondary-text-color);
  font-weight: 300;
  margin-bottom: 1rem;
  font-size: 250%;   
}

.headerText{
  font-family: 'Poppins', sans-serif;
  color: var(--secondary-text-color);
  font-weight: 300;
  margin-bottom: 3rem;  
}

.wideButton{
  width: 100%;
  max-width: 20rem;
}

.rightFloat{
  float: right;
}

.primaryButton{
  border: none;
  border-radius: 10rem;
  font-size: 1rem;
  padding: 0.5rem;

  font-family: 'Poppins', sans-serif;
  background-color: var(--primary-color);
  color: var(--secondary-text-color);
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

  transition: all 0.1s ease-in;
}

.secondaryButtonSelected{
  border: none;
  font-size: 1rem;
  padding: 0.5rem;

  font-family: 'Poppins', sans-serif;
  background-color: transparent !important;
  color: var(--primary-color);

  transition: all 0.1s ease-in;
}

.secondaryButtonSelected:hover{
  color: var(--primary-text-color);
}

.secondaryButton{
  border: none;
  font-size: 1rem;
  padding: 0.5rem;

  font-family: 'Poppins', sans-serif;
  background-color: transparent !important;
  color: var(--primary-text-color);

  transition: all 0.1s ease-in;
}

.secondaryButton:hover{
  color: var(--primary-color);
}

.neutralButton{
  border: none;
  border-radius: 10rem;
  font-size: 1rem;
  padding: 0.5rem;

  font-family: 'Poppins', sans-serif;
  background-color: var(--neutral-color);
  color: var(--secondary-text-color);
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  
  transition: all 0.1s ease-in;
}

.warningButton{
  border: none;
  border-radius: 10rem;
  font-size: 1rem;
  padding: 0.5rem;

  font-family: 'Poppins', sans-serif;
  background-color: var(--warning-color);
  color: var(--primary-text-color);
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  
  transition: all 0.1s ease-in;
}

.warningButtonIcon{
  border: none;
  font-size: 1rem;
  padding: 0.5rem;

  font-family: 'Poppins', sans-serif;
  background-color: transparent !important;
  color: var(--warning-color);
  
  transition: all 0.1s ease-in;
}

.warningButtonIcon:hover{
  color: var(--primary-text-color);
}

.dangerButton{
  border: none;
  border-radius: 10rem;
  font-size: 1rem;
  padding: 0.5rem;

  font-family: 'Poppins', sans-serif;
  background-color: var(--danger-color);
  color: var(--secondary-text-color);
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  
  transition: all 0.1s ease-in;
}

.dangerButtonIcon{
  border: none;
  font-size: 1rem;
  padding: 0.5rem;

  font-family: 'Poppins', sans-serif;
  background-color: transparent !important;
  color: var(--danger-color);
  
  transition: all 0.1s ease-in;
}

.dangerButtonIcon:hover{
  color: var(--primary-text-color);
}

.primaryButton:active, 
.secondaryButton:active,
.secondaryButtonSelected:active,
.neutralButton:active, 
.warningButton:active,
.warningButtonIcon:active,
.dangerButton:active,
.dangerButtonIcon:active{
  transform: scale(.9);
  filter: brightness(150%);
}

.primaryButton:disabled, 
.secondaryButton:disabled,
.secondaryButtonSelected:disabled,
.neutralButton:disabled, 
.warningButton:disabled,
.warningButtonIcon:disabled,
.dangerButton:disabled,
.dangerButtonIcon:disabled{
  background-color: var(--background-color);
  box-shadow: none !important;

  transform: scale(1) !important;
  filter: brightness(100%) !important;
}

.primaryButton:hover,  
.neutralButton:hover, 
.warningButton:hover, 
.dangerButton:hover{   
  filter: brightness(125%);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.content{
  display: grid;
  gap: 1rem;
}

.prompt{
  padding: 1rem;
  border-radius: 1rem;
  max-width: calc(100vw - 4rem);
  overflow-wrap: break-word;

  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  color: var(--primary-text-color);
  background-color: var(--card-color);
}

.promptVoteCount{
  font-size: 0.8rem;
  margin-bottom: 1rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  opacity: 0.8;
}

.voteCounter{
  font-size: 0.8rem;
  margin-bottom: 1rem;
  margin-top: 0.75rem;

  opacity: 0.8;
}

.promptContent{
  margin-bottom: 1rem;
}

.topVotedPrompt{
  border: 3px solid var(--primary-color);
}

.topVotedPromptBadge{
  text-align: right;
  font-size: 0.8rem;
  opacity: 0.8;
}

.topVotedPromptBadge .material-symbols-outlined{
  font-size: 1rem;
}

.voteBar{
  display: grid;
  grid-template-columns: auto auto;
}

.promptDivider{
  text-align: center;
  margin: 2rem;
  
  color: var(--primary-text-color);
  opacity: 0.7;
}

.inputTextArea{
  display: block;
  width: calc(100% - 3rem);
  margin: 0.5rem;
  margin-bottom: 1rem;
  padding: 1rem;

  border: none;
  border-radius: 1rem;
  background-color: var(--background-color);
  color: var(--primary-text-color);
  font-family: 'Roboto', sans-serif;
  outline: 0 !important;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

  transition: all 0.2s ease-in;
}

.inputTextArea:focus{
  background-color: var(--card-color);
}

.textareaNoBorder{
  height: auto !important;
  padding: 0 !important; 
  border-radius: 0!important;
  width: calc(100% - 1rem) !important;
  box-shadow: none !important;
  background-color: transparent !important;
}

.popupContainer{
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;

  backdrop-filter: brightness(50%) blur(5px);
}

.popup{
  width: 20rem;
  padding: 1rem;
  border-radius: 1rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 150;

  box-shadow: rgba(12, 12, 13, 0.3) 0px 8px 24px;
  color: var(--primary-text-color);
  background-color: var(--background-color);
}

.writeMessage{
  position: fixed;
  bottom: 0;
  width: calc(100vw - 2rem);
  padding: 1rem;
  z-index: 50;
  display: grid;
  grid-template-columns: auto 3rem;

  box-shadow: rgba(12, 12, 13, 0.3) 0px 8px 24px;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  background-color: var(--card-color);
}

.writeMessage .primaryButton{
  height: 3rem;
  width: 3rem;
  margin-top: 0.5rem;
  bottom: 1rem;
}

.popupTitle{
  margin-top: 0;

  font-family: 'Poppins', sans-serif;
}

.pw-2{
  padding-left: 1rem;
  padding-right: 1rem;
}

.mr-1{
  margin-right: 0.5rem;
}

.mb-2{
  margin-bottom: 1rem;
}

.mt-2{
  margin-top: 1rem;
}

.material-symbols-outlined {
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 48;
  vertical-align: middle;
}

.material-symbols-filled {
  font-variation-settings:
  'FILL' 1,
  'wght' 400,
  'GRAD' 0,
  'opsz' 48;
  vertical-align: middle;
}
